/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Greenlogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';
import wrtsapp from '../images/wrtsapp.jpg';
import wrtsgoogle from '../images/wrtsgoogle.jpg';
import mobileapp from '../images/mobileapp.jpg';
import { useAppContent } from '../hooks/getAppContent';

const openplay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");
    const AppData = useAppContent();
    const appDownload = AppData.wordpressPage.acf;

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
             <section className="page-section notbottompaddsec nopaddbottsec">
                <div className="container">
                    <div className="container flexwrap flex-uped whywebluelist">
                    <div className="whylistlft flexbox whylisttxt todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.open_play_basic_info_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            loading="lazy"
                            alt="open img"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                            <h2 className="bluetxt">FULL USE OF OUR INDOOR PLAY GYM</h2>
                            <p className="biggertxt">We offer Open Play Daily, which is when children are welcome to enjoy full use of our sensory-safe gym with parent supervision. Our gym features <a href="/rock/">therapy equipment</a>, an arts & craft and fine motor area, a calming room and more. We welcome children of all ages from crawlers up to tweens and teens, who will enjoy our Teen Lounge/ Game Room!</p> 
                            <p className="biggertxt">
                            OUR DAILY OPEN PLAY HOURS VARY BASED ON PRIVATE RENTALS AND BIRTHDAY PARTY RESERVATIONS. PLEASE CHECK OUR SCHEDULE OR CALL FOR MORE INFORMATION.
                            </p> 
                            <p className="biggertxt">RESERVATIONS ARE AVAILABLE FOR OPEN PLAY ON WEEKENDS, HOLIDAYS AND MASS SCHOOL VACATION DAYS THROUGH THE WE ROCK THE SPECTRUM MOBILE APP (WEEKEND RESERVATIONS OPEN THURSDAY AFTERNOONS).</p> 
                            <div className="abouthomeapp contapp new_layout_app">
                                <h6
                                    className="bluetxt"
                                    dangerouslySetInnerHTML={{
                                        __html: appDownload.app_download_text,
                                    }}
                                />
                                <a
                                    href="https://apps.apple.com/us/app/we-rock-the-spectrum-kids-gym/id1204873096"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={wrtsapp} alt="wrtsapp" />
                                </a>
                                {` `}
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.werockthespectrumkidsgym&hl=en"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={wrtsgoogle} alt="wrtsgoogle" />
                                </a>
                                <img className="appmob" src={mobileapp} alt="mobileapp" />
                            </div>
                            <a className="wrtsbtn yellowbtn wrts_standbtn" href="/schedule/">VIEW CALENDAR FOR OPEN PLAY SCHEDULE</a>
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_title,
                            }}
                        />
                        <ul
                            className="startxt normalfontlist"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_content,
                            }}
                        />
                    </div>
                </div>
                   
                </div>
            </section> 

          

            

            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
             <div className="container smallestwdt">
                    <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_title,
                        }}
                    />
                    <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_description,
                        }}
                    />

                    {post.acf.open_play_membership_list.map((membership, i) => (
                        <h5 key={`kt_${i}`}>
                            <span
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_name,
                                }}
                            />
                            {' : '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_price,
                                }}
                            />
                        </h5>
                    ))}
                    {/* <p>
                    Call for additional pricing for families with more than 3 children.
                    </p> */}
                </div>
                {/* <div className="container flexwrap whywebluelist">
                    <div className="whylistlft flexbox whylisttxt todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.open_play_basic_info_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            loading="lazy"
                            alt="open img"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_title,
                            }}
                        />
                        <ul
                            className="startxt normalfontlist"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_content,
                            }}
                        />
                    </div>
                </div> */}
            </section>

            {/* <section className="page-section bg-secondary text-white centersec">
                <div className="container smallestwdt">
                    <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_title,
                        }}
                    />
                    <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_description,
                        }}
                    />

                    {post.acf.open_play_membership_list.map((membership, i) => (
                        <h5 key={`kt_${i}`}>
                            <span
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_name,
                                }}
                            />
                            {' : '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_price,
                                }}
                            />
                        </h5>
                    ))}
                    
                </div>
            </section> */}
            <section className="page-section openphours">
                <div className="container smallestwdt">
                    <p className='redtxt_cent'>Please note: Purchasing an Open Play pass is not making a reservation.
                        Weekend and Holiday Reservations are available through our app.
                        Purchases can be made in person in the lobby.
                        Sibling Rate available in person or on the app when reservations are open.
                        Same Day Open Play Drop-In Tickets purchased online are for same day use and expire after 24 hours.
                    </p>
                    <div className="twothirdcol">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_schedule_title,
                            }}
                        />
                        <p className='redtxt'> HOURS VARY WEEKLY DUE TO SPECIAL EVENTS. PLEASE CHECK OUR SCHEDULE BEFORE YOU VISIT TO MAKE SURE THE FOLLOWING IS BEING OBSERVED.</p>

                        <p className='reg_p'>  Our hours may change without advance notice for unforeseen circumstances. Changes to our schedule are announced on our Social Media Pages.</p>
                        <ul className="startxt starlistspacing">
                            {/* <li className="greentxt"> Open 7 Days a week, Open Play hours vary. Please check our 
                                <a href="/schedule/">schedule</a> or Social Media or call ahead.</li> */}
                            <li className="greentxt">MONDAY: 9:30-6:30</li>
                            <li className="greentxt">TUESDAY: 10:30-6:30 (4:30-6:30 T(w)een Meet up (Ages 8 and up, younger siblings welcome)</li>
                            <li className="greentxt">WEDNESDAY 9:30-6:30 (9:30-11:30 Toddler Open Play, Ages 0-4 only)
</li>
                            <li className="greentxt">THURSDAY: 9:30-6:30
</li>
                            <li className="greentxt">FRIDAY: 10:30-6:30
</li>
                            <li className="bluetxt">SATURDAY AND SUNDAY: VISIT <a href="/schedule/">SCHEDULE</a> PAGE FOR HOURS</li>
                     {/* <li className="greentxt">PLEASE CALL OR CHECK OUR <a href="https://www.facebook.com/WeRockCleveland" target='_blank'>FACEBOOK</a>/<a href="https://www.instagram.com/werockcleveland" target='_blank'>INSTAGRAM</a> AS WE MAY BE CLOSED FOR PRIVATE EVENTS.</li>  */}
                    {/* {post.acf.open_play_schedule_work_days.map(
                                (schedule, i) => (
                                    <li className="greentxt" key={i}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_day,
                                            }}
                                        />
                                        : 
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}
                            {post.acf.open_play_schedule_weekend.map(
                                (schedule, i) => (
                                    <li className="bluetxt" key={`ii_${i}`}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_day,
                                            }}
                                        />
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}   */}
                        </ul>
                        <p>
                        HOURS VARY BASED ON PRIVATE RENTALS AND BIRTHDAY PARTY RESERVATIONS. <br />PLEASE CHECK OUR SCHEDULE OR CALL FOR MORE INFORMATION.
                        </p>
                    </div>

                    <div className="onethirdcol">
                        <a
                            href="/register"
                            style={{ background: 'none', padding: 0 }}
                        >
                            <h6
                                className="greentxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.open_play_registration_title,
                                }}
                            />
                        </a>
               {post.acf.open_play_register_widget_codes.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code,
                                    }}
                                />
                            )
                        )}   
                        
                    </div>
                </div>
            </section>
            <div className="container text_center mt-15">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>
            <section className="page-section">
                <div className="container smallestwdt">
                    <div className="greenlft">
                        <img src={Greenlogo} alt="greenlogo" loading="lazy" />
                    </div>

                    <div className="greenrgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_green_products_title,
                            }}
                        />
                        <p
                            className="biggertxt lastitem"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.open_play_green_products_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default openplay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                open_play_basic_info_content
                open_play_basic_info_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_basic_info_title
                open_play_content_right
                open_play_content_second
                open_play_green_products_content
                open_play_green_products_title
                open_play_image_left {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_membership_description
                open_play_membership_list {
                    open_play_membership_name
                    open_play_membership_price
                }
                open_play_membership_title
                open_play_please_call_ahead_text
                open_play_registration_title
                open_play_register_widget_codes {
                    open_play_register_widget_code
                }
                open_play_schedule_title
                open_play_schedule_work_days {
                    open_play_schedule_day
                    open_play_schedule_time
                }
                open_play_schedule_weekend {
                    open_play_schedule_weekend_day
                    open_play_schedule_weekend_time
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
